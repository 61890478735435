import React from "react"
import {graphql} from "gatsby"
import {injectIntl, useIntl} from "react-intl"
import {Link} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../layout"

const Course = (props) => {
    const intl = useIntl()
    const lang = intl.locale
    const course = props.data.course

    if(!course){
        return (
            <Layout/>
        )
    }

    let par = 'Физика'
    if (lang === "ru") {
        switch (course.frontmatter.parent) {
            case 'education':
                par = 'Образование';
                break;
            case 'math':
                par = 'Математика';
                break;
            case 'software':
                par = 'Программное обеспечение';
                break;
            default:
                par = 'Физика';
        }
    } else {
        switch (course.frontmatter.parent) {
            case 'education':
                par = 'Education';
                break;
            case 'math':
                par = 'Maths';
                break;
            case 'software':
                par = 'Software';
                break;
            default:
                par = 'Physics';
        }
    }
    return (
        <Layout>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{margin: 0}}>
                    <li className="breadcrumb-item">
                        <Link to={`/projects/${course.frontmatter.parent}`}>{par}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{course.frontmatter.shortTitle}</li>
                </ol>
            </nav>

            <h1 style={{marginTop: `15px`, textAlign: `center`}}>{course.frontmatter.title}</h1>
            <div>{Parser(course.html)}</div>
        </Layout>
    )
}

export default injectIntl(Course)

export const CourseQuery = graphql`
    query CourseByPath($path: String){
        course: markdownRemark(
            frontmatter: {slug: {eq: $path}}
        ){
            html
            frontmatter{
                title
                shortTitle
                path
                parent
                slug
            }
        }
    }`